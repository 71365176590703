import React,{Component}from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import TablePaginationActions from "../../presentational/table-pagination-actions";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorEdit = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class AccessManagement extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},
            search: '',

            roles: [],
            showDetail: false,
            showForm: false,
            showFormEdit: false,
            showPassword: false,
            showEdit:false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            idUser: '',
            fullName:'',
            email: '',
            roleId: '',
            password: '',

            exFullName: '',
            exFirstName: '',
            exLastName: '',
            exEmail: '',
            exRole: '',
            exRoleId: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleShowForm = this.handleShowForm.bind(this);
    }

    componentDidMount() {
        Api.get('/roles', true).then(resp => {
            if(resp.data) {
               this.setState({
                   roles:resp.data.roles,
               })
            }
        }).catch(err => {
            console.log(err);
        });

        this.__fetchData(false);
    }

    __fetchData = update => {
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let route = '/users?perPage=' + this.state.perPage + '&pageNumber=' + page + '&keyword=' + this.state.search;

        let idUser = this.state.idUser;

        Api.get(route, false).then(resp => {

            if (resp.data) {

                let data = '';

                resp.data.users.forEach(function(o){

                    if(o.id === idUser){
                        o.selected = true;
                        data = o;
                    }else{
                        o.selected = false;
                    }

                });

                if(typeof data === 'object'){

                    this.handleShowDetail(data);

                }

                this.setState({
                    rows: resp.data.users,
                    total: resp.data.meta.total,
                    perPage: parseInt(resp.data.meta.perPage),
                    currentPage: resp.data.meta.currentPage,
                    currentPageTable: resp.data.meta.currentPage - 1,
                    totalData: resp.data.meta.totalData,
                    loading: false,
                })

            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleKeyPressSearch = (event) => {

        if(event.key === 'Enter'){

            this.handleClickSearch();

        }

    };

    handleClickSearch(){

        this.setState({
            loading: false,
        });

        this.__fetchData(false);

    };

    handleShowDetail = data => {

        let arrObjRows = this.state.rows;

        arrObjRows.forEach(function(o){
            o.selected = o.id === data.id;
        });

        this.setState({
            showEdit: false,
            showDetail: true,
            showForm: false,
            showFormEdit: false,
            idUser: data.id,
            status: data.status,
            exStatus: data.status,
            exFullName: data.fullName,
            exFirstName: data.firstName,
            exLastName: data.lastName,
            exEmail: data.email,
            exRole: data.role.name,

            fullName: data.fullName === null ? '' : data.fullName,
            email: data.email === null ? '' : data.email,
            roleId: data.role.id === null ? '' : data.role.id,
            rows: arrObjRows
            }
        );

    };

    handleShowForm = e => {
        e.preventDefault();
        this.setState(prevState => ({
                showDetail: false,
                showFormEdit: false,
                showForm: !prevState.showForm,
                fullName: '',
                email: '',
                roleId: '',
                password: '',
                idUser: ''
        })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleSubmit = e => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            email: this.state.email,
            password: this.state.password,
            fullName: this.state.fullName,
            roleId: this.state.roleId,
        };

        Api.post('/register', params, false).then(resp => {

            this.setState({
                email: '',
                password: '',
                fullName: '',
                roleId: '',
                currentPage:1,
                currentPageTable:0,
                loadingButton: false
                }
            );

            this.__fetchData(false);

            this.showMessage(true, 'User successfully added');

        }).catch(err => {

            this.setState({
                errors: err.errors,
                loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };

    handleUpdate = e => {
        e.preventDefault();

        if (!validatorEdit.allValid()) {

            this.setState({
                    errors: validatorEdit.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            password: this.state.password,
            status: this.state.status,
            fullName: this.state.fullName,
            roleId: this.state.roleId,
        };

        Api.put('/users/'+this.state.idUser, params, false).then(resp => {

            let profile = AuthHelper.getProfile();

            if(profile.id === this.state.idUser) {
                profile.fullName = this.state.fullName;
                AuthHelper.setProfile(profile);
                this.props.setProfile(profile);
            }

            this.setState({
                    showDetail: true,
                    showFormEdit: false,
                    email: '',
                    password: '',
                    fullName: '',
                    status: '',
                    currentPage:1,
                    currentPageTable:0,
                    loadingButton: false
                }
            );

            this.__fetchData(false);

            this.showMessage(true, 'User successfully updated');

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword:(!this.state.showPassword)
        })
    };

    render() {
        const perms = this.props.permission_state;
        return (
            <div>
                <h2 className='text-title-big' >Access management</h2>
                <div className="row" style={{padding:'0px 15px'}}>
                    <div className="card col-md-6 col-lg-5" style={{marginTop:'20px'}}>
                        <div className="row">
                            <div className="col-5 col-lg-6 pr-0">
                                {perms.includes('register') &&
                                <button className="btn btn-blue" onClick={this.handleShowForm}>ADD USER</button>
                                }
                            </div>
                            <div className="col search-bar">
                                <TextField
                                    type="text"
                                    id="adornment-search"
                                    name="search"
                                    placeholder="Search users"
                                    onChange={(e) => this.handleChange(e,'search')}
                                    onKeyPress={this.handleKeyPressSearch}
                                    value={this.state.search}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickSearch}
                                                >
                                                    <i className="fas fa-search"> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <Table className="table-list mt-3" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>First Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={5} style={{ textAlign: "center" }}>No users</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    onClick={() => this.handleShowDetail(row)}
                                                    selected={row.selected}>
                                                    <TableCell>{row.lastName === null ? 'NA' : row.lastName}</TableCell>
                                                    <TableCell>{row.firstName === null ? 'NA' : row.firstName}</TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                    <div className="col-md-6 col-lg-7" style={{marginTop:'20px'}}>
                        <Paper className={this.state.showDetail ? "show" : "hide"}>
                            <h3>{this.state.exFullName}</h3>
                            <p><strong>Role: {this.state.exRole}</strong></p>
                            <form name="update" id="update" noValidate>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword3" className="col-sm-3 col-md-6 col-lg-3 col-form-label pr-sm-0">Password:</label>
                                    <div className="col-sm-6 pl-sm-0">
                                        {
                                            this.state.showEdit ?
                                                (
                                                    <div>
                                                        <TextField
                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                            id="adornment-password-register"
                                                            name="password"
                                                            onChange={(e) => this.handleChange(e,'password')}
                                                            value={this.state.password}
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="Toggle password visibility"
                                                                            onClick={this.handleClickShowPassword}
                                                                        >
                                                                            {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                                <i className="fas fa-eye-slash"> </i>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        {validatorEdit.message('password', this.state.password, 'min:8')}
                                                        <div className='text-danger'>{this.state.errors.password}</div>
                                                    </div>
                                                ) :
                                                (
                                                    <p className="mt-2 mb-0">*****</p>
                                                )
                                        }

                                    </div>
                                </div>
                                <fieldset className="form-group">
                                    <div className="row">
                                        <legend className="col-form-label col-sm-3 col-md-6 col-lg-3  pt-sm-0">Status:</legend>
                                        <div className="col-sm-6 pl-sm-0">
                                            {
                                                this.state.showEdit ?
                                                    (
                                                        <div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="status"
                                                                    onChange={(e) => this.handleChange(e,'status')}
                                                                    id="statusActive"
                                                                    value="active"
                                                                    checked={this.state.status === 'active' && 'checked'}
                                                                />
                                                                <label className="form-check-label" htmlFor="statusActive">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="status"
                                                                    onChange={(e) => this.handleChange(e,'status')}
                                                                    id="statusSuspend"
                                                                    value="suspend"
                                                                    checked={this.state.status === 'suspend' && 'checked'}
                                                                />
                                                                <label className="form-check-label" htmlFor="statusSuspend">
                                                                    Suspended
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <p className="text-capitalize med mb-0">{this.state.exStatus}</p>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </fieldset>
                                {
                                    this.state.showEdit ?
                                        (
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <button className="btn btn-blue-trans" onClick={this.handleCloseEdit}>Cancel</button>
                                                </div>
                                                <div className="col text-right">
                                                    <button
                                                        className="btn btn-blue-trans"
                                                        onClick={this.handleUpdate}
                                                        disabled={this.state.loadingButton && 'disabled' }>
                                                        Save Changes { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }</button>
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <button className="btn btn-blue-trans" onClick={this.handleShowEdit}>Edit</button>
                                        )
                                }

                            </form>
                        </Paper>

                        <Paper className={this.state.showForm ? "show" : "hide"}>
                            <form name="edit" id="edit" className="row justify-content-between" noValidate>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <TextField
                                            type='text'
                                            id='fullName'
                                            name="fullName"
                                            label="Full Name"
                                            onChange={(e) => this.handleChange(e, 'fullName')}
                                            minLength={4}
                                            maxLength={50}
                                            value={this.state.fullName}
                                            fullWidth
                                        />
                                        {validator.message('fullName', this.state.fullName, 'required')}
                                        <div className='text-danger'>{this.state.errors.fullName}</div>
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            type='text'
                                            id='email'
                                            name="email"
                                            label="Email"
                                            onChange={(e) => this.handleChange(e, 'email')}
                                            minLength={4}
                                            maxLength={50}
                                            value={this.state.email}
                                            fullWidth
                                        />
                                        {validator.message('email', this.state.email, 'email|required')}
                                        <div className='text-danger'>{this.state.errors.email}</div>
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            id="adornment-password"
                                            name="password"
                                            label="Password"
                                            onChange={(e) => this.handleChange(e,'password')}
                                            value={this.state.password}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                        >
                                                            {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                <i className="fas fa-eye-slash"> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {validator.message('password', this.state.password, 'required|min:8')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            select
                                            id="roleId"
                                            name="roleId"
                                            label="Role"
                                            onChange={(e) => this.handleChange(e, 'roleId')}
                                            minLength={4}
                                            maxLength={50}
                                            value={this.state.roleId}
                                            fullWidth
                                        >
                                        {this.state.roles.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.displayName}
                                            </MenuItem>
                                        ))}
                                        </TextField>
                                        {validator.message('roleId', this.state.roleId, 'required')}
                                        <div className='text-danger'>{this.state.errors.roleId}</div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-xl-4">
                                    <button
                                        onClick={this.handleSubmit}
                                        className="btn btn-blue-trans full"
                                        disabled={this.state.loadingButton && 'disabled' }>
                                        Submit { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                    </button>
                                </div>
                            </form>
                        </Paper>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement);