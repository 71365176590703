import React,{Component} from 'react';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import TicketLists from '../../presentational/ticket-list';

require('dotenv').config();

class Tickets extends Component {
    render() {
        return (
            <TicketLists />
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);