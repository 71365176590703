import React,{Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Api from "../../../libraries/api";
import AuthHelper from "../../../libraries/auth-helper";
import {toast} from "react-toastify";
import TextField from '@material-ui/core/TextField';
import SimpleReactValidator from "simple-react-validator";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Settings extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            complete: false,
            open: false,
            states: '',

            errors: {},
            confirm: '',

        };
    }

    handleOpen = (type) => {
        this.setState({
            open: true,
            states: type,
        });
    };

    handleCancel = () => {
        this.setState({
            open: false
        })
    };

    handleDelete = () => {
        this.setState({
            errors: {},
            loading:true,
        });

        if (!validator.allValid()) {

            this.setState({
                errors: validator.getErrorMessages(),
                loading:false,
                }
            );

            return false;

        }

        if(this.state.confirm === "flush") {
            Api.post('/administrator/flush/database')
               .then(resp => {
                   this.setState({
                       loading:false,
                       open:false
                   });
                   toast.success('Flush success', {
                       position: toast.POSITION.BOTTOM_RIGHT
                   });

                   if(resp.success) {
                       AuthHelper.logOut();
                   }



               })
               .catch(err => {

                   console.log(err);
                   this.setState({
                       loading:false,
                       open:false
                   });
               });
        }else {
            this.setState({
                    errors: {
                        confirm: "invalid confirmation text"
                    },
                    loading:false,
                }
            );
        }
    };

    handleSeed = () => {
        this.setState({
            loading:true,
        });

        Api.post('/administrator/seeder')
            .then(resp => {

                this.setState({
                    loading:false,
                    open:false
                });
                toast.success('Seeder success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(err => {

                toast.error(err.errors.messages, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({
                    loading:false,
                    open:false
                });
            });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    render() {
        if (this.state.complete) return <h1>Purchase Complete</h1>;

        return (

            <div>
                <h1>Settings</h1>

                <p>Flush all the data : <button className="btn btn-blue" onClick={() => this.handleOpen('flush')}>Flush</button></p>
                <p>Populate data dummy : <button className="btn btn-blue" onClick={() => this.handleOpen('seed')}>Seeder</button></p>


                {/*<StripeProvider apiKey="pk_test_R1u2j91wFTpY6rg9gHI6V97000FOkYcLOS">
                    <div className="example">
                        <Elements>
                            <CheckoutForm />
                        </Elements>
                    </div>
                </StripeProvider>*/}

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.open}
                >
                    <DialogTitle id="confirmation-dialog-title">Are you absolutely sure?</DialogTitle>
                    <DialogContent dividers>
                        {
                            (this.state.states === 'flush') ?
                                (
                                    <>
                                        <div className="alert-warning p-2">
                                            <p className="mb-0">This action <strong>cannot</strong> be undone. This will permanently delete all data in database.</p>
                                        </div>
                                        <p className="mt-2">Please type in "flush" to confirm.</p>

                                        <TextField
                                            type='text'
                                            id='confirm'
                                            name="confirm"
                                            label="Type"
                                            onChange={(e) => this.handleChange(e, 'confirm')}
                                            minLength={4}
                                            maxLength={50}
                                            value={this.state.confirm}
                                            fullWidth
                                        />
                                        {validator.message('confirm', this.state.confirm, 'required')}
                                        <div className='text-danger'>{this.state.errors.confirm}</div>

                                    </>
                                ):
                                    (
                                        <p>This action will add dummy data.</p>
                                    )
                        }

                    </DialogContent>
                    <DialogActions>
                        <button onClick={this.handleCancel} className="btn btn-blue-trans">
                            Cancel
                        </button>
                        {
                            (this.state.states === 'flush') ?
                                <button
                                    onClick={this.handleDelete}
                                    className="btn btn-blue"
                                    disabled={this.state.loading && 'disabled' }>
                                    Submit { this.state.loading && <i className="fa fa-spinner fa-spin"> </i> }
                                    </button>
                                :
                                <button
                                    onClick={this.handleSeed}
                                    className="btn btn-blue"
                                    disabled={this.state.loading && 'disabled' }>
                                    Submit { this.state.loading && <i className="fa fa-spinner fa-spin"> </i> }
                                    </button>
                        }

                    </DialogActions>
                </Dialog>
            </div>

        )

    }
}

export default Settings;

