import React, {Component} from "react";
import Api from "../../../libraries/api";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Link} from "react-router-dom";
import DateFormat from "../../../libraries/data-format-helper";

export default class SalesDeliveredLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            showDetail: false,
            search: '',
            purchaseData: [],
        };
        this.getPurchaseDelivered();
        this.handlePurchaseDelivered = this.handlePurchaseDelivered.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getPurchaseDelivered() {
        let currentPage = this.state.currentPage + 1;
        let url = "/purchase/type/delivered?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["Purchases"],
                loading: false,
                totalData: resp["data"]["meta"]["totalData"]
            });
        });
    }

    handleClose() {
        this.setState({showDetail: false})
    }

    handlePurchaseDelivered(purchaseId) {
        let url = "/purchase/detail/" + purchaseId;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                showDetail: true,
                purchaseData: resp["data"]["Purchase"]
            })
        });

    }

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                rows: [],
                currentPage: 0,
                currentPageTable: 0,
                loading: true,
            }, () => {
                this.getPurchaseDelivered();
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getPurchaseDelivered();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getPurchaseDelivered()
        });
    };

    render() {
        return (
            <div className="row">
                <div className="col-12 card">
                    <div className="row align-items-center">
                        <div className="col-md-4 search-bar">
                            <TextField
                                type="text"
                                id={"adornment-search-delivered"}
                                name="search"
                                placeholder="ordered"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Search button"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-2 filter">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    margin="normal"
                                    id={"start_date_delivered"}
                                    label="Date"
                                    format={"MM/DD/YYYY"}
                                    value={this.state.start_date}
                                    onChange={(date) => this.handleChangeSearch(date, 'start_date')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <PurchaseDeliveredDetail showDetail={this.state.showDetail}
                                             handleClose={this.handleClose}
                                             purchaseData={this.state.purchaseData}/>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Request Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Delivered Date</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Zip Code</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <PurchaseDeliveredTable rows={this.state.rows}
                                                            handlePurchaseDelivered={this.handlePurchaseDelivered}/>
                                )
                            }

                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class PurchaseDeliveredTable extends Component {

    render() {
        let handlePurchaseDelivered = this.props.handlePurchaseDelivered;
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={i} onClick={() => handlePurchaseDelivered(item.id)}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{DateFormat.dateTime(item.created)}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{DateFormat.dateTime(item.updatedAt)}</TableCell>
                            <TableCell>{item.userName}</TableCell>
                            <TableCell>{item.postalCode}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}

class PurchaseDeliveredDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryProvider: [],
            deliveryProviderId: '',
            trackId: ''
        }
    }

    componentDidMount() {
        /*Delivery Catalog*/
        Api.get('/delivery/provider', true).then(resp => {
            if (resp.data) {
                this.setState({
                    deliveryProvider: resp.data.deliveryProvider,
                    deliveryProviderId: resp.data.deliveryProviderDefaultId
                });
            }
        })
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    render() {
        let handleClose = this.props.handleClose;
        let purchase = this.props.purchaseData;
        return (
            <Dialog
                maxWidth={'md'}
                open={this.props.showDetail}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className="dialog-custom">
                <DialogTitle id="form-dialog-title">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <span>Order</span>
                        </div>
                        <div className="col-md-6">
                            <p className="mb-0"><label className="title d-inline">Status</label>
                                <i className="status text-capitalize"> Delivered</i>
                            </p>
                        </div>
                    </div>
                    <button onClick={handleClose} className="btn-close">
                        <i className="fas fa-times"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="title">Account number</label>
                                <p className="pl-2 pl-lg-3">{purchase["userId"]}</p>
                                <Link to={'/accounts/detail/' + purchase["userId"]} className="btn btn-blue-trans mb-3">Go
                                    to Account</Link>
                                <label className="title">Contact and Shipping</label>
                                <p className="pl-2 pl-lg-3">
                                    {purchase["fullName"]}<br/>
                                    {purchase["address"]}<br/>
                                    {purchase["city"]}, {purchase["stateId"]} {purchase["postalCode"]}<br/>
                                    {purchase["email"]}<br/>
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="title">Request Date</label>
                                        <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["created"])}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Delivered Date</label>
                                        <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["updatedAt"])}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="title">Delivery Provider</label>
                                        <p className="pl-2 pl-lg-3">{purchase["provider"]}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Tracking Id</label>
                                        <p className="pl-2 pl-lg-3">{purchase["trackId"]}</p>
                                    </div>
                                    <div className="col-12">
                                        <label className="title"></label>
                                        <p className="pl-2 pl-lg-3"></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="title">Toll Tag Id</label>
                                        <p className="pl-2 pl-lg-3">{purchase["transponderShippedId"]}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Toll Tag Fee</label>
                                        <p className="pl-2 pl-lg-3">$0.0</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}